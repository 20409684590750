/* CommonPage.css */
.content {
  padding-top: 40px;
  width: 100%;
  max-width: 800px;
  margin: 20px auto 20px; /* Add margin-bottom to create space for BottomBar */
  color: #fff;
  background-color: #1d1d1d;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1; /* Allow .content to take up remaining space */
}

/* Add new CSS styles for GuidesPage */
.guide-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Add new CSS styles for GuideModal */
.guide-modal {
  width: 251px; /* Set the width of each GuideModal to one-third of the available space minus the gap */
  height: 200px; /* Set a fixed height for the GuideModal container */
  margin: 10px auto; /* Adjust margin to create spacing between modals */
  border: 1px solid #ccc; /* Add a border around each modal */
  background-color: #303030; /* Set the background color for the modal */
  text-decoration: none; /* Remove underline from links inside GuideModal */
  color: inherit; /* Inherit the text color from the parent element */
  position: relative; /* Set the positioning context for the overlay */
  overflow: hidden; /* Hide any content that overflows the modal */
}

.guide-modal img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container without distorting its aspect ratio */
  display: block;
  position: relative; /* Set the positioning context for this element */
  z-index: 1; /* Place the image above other elements inside the modal */
}

/* Add a faded bottom bar area */
.guide-modal:before {
  content: '';
  position: absolute;
  bottom: 0; /* Align the black bar to the bottom of the modal */
  left: 0;
  width: 100%;
  height: 38%; /* Adjust the height of the black bar as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Set the color and opacity of the black bar */
  z-index: 2; /* Place the black bar above the image */
}

/* Wrap Date, Author, and Description in a container and apply border to it */
.guide-modal .guide-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  background: linear-gradient(rgba(48, 48, 48, 0), rgba(99, 99, 99, 0.8)); /* Add a gradient background */
  z-index: 3; /* Place the .guide-info above the .guide-modal:before element and the image */
}

.guide-modal h3,
.guide-modal p {
  margin: 5px 0;
  color: #fff; /* Set the text color for date and author */
}

.guide-modal .social-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.guide-modal .social-icons a {
  margin: 0 5px;
  color: #fff;
  font-size: 20px;
}

.guide-modal a {
  display: block;
  margin-top: 10px;
}

.content h1 {
  margin-top: 70px; /* Adjust the value as needed */
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the page takes up at least the full viewport height */
}

.content-wrapper {
  flex: 1; /* Allow content to take up remaining space */
}

/* Media Query for smaller screens (max-width: 600px) */
@media screen and (max-width: 600px) {
  .content h1 {
    text-align: center;
  }

  .guide-list {
    margin: 0;
    display: flex;
    flex-direction: column; /* Display GuideModals vertically */
    align-items: center; /* Center GuideModals horizontally */
  }

  .guide-modal {
    width: calc(100% - 20px); /* GuideModal takes up full width with spacing */
    max-width: 350px; /* Set a maximum width for GuideModal on smaller screens */
    height: 200px; /* Remove fixed height to allow modal to adjust based on content */
    margin: 10px auto; /* Adjust margin to create spacing between modals */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
  }

  .guide-modal img {
    height: 200px; /* Adjust the height of the image on smaller screens */
  }

  .guide-modal .guide-info {
    padding: 8px; /* Adjust padding for the guide-info container */
    font-size: 10px; /* Adjust font size for date, author, and description */
  }
}

/* GuidePage.css */
.guide-page {
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.guide-container {
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #edecff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.guide-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.guide-title {
  font-size: 36px;
  font-weight: bold;
  color: #333333;
}

.guide-date {
  font-size: 14px;
  color: #777777;
}

.guide-body {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px;
  color: #444444;
}

.guide-author-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 18px;
}

.guide-author {
  color: #000000;
  margin-right: 10px;
  font-size: 18px;
}

.guide-author-links a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.guide-author-links a:not(:last-child) {
  margin-right: 6px;
}

.guide-author-links a svg {
  margin-top: 9px;
  margin-bottom: 2px;
}

.guide-author-links a:hover {
  color: #0056b3;
}

/* Container to arrange content and BottomBar in a column layout */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* BottomBar that is specifically just for this page */
.bottom-bar-guidepage {
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto; /* Push the BottomBar to the bottom of the page */
  padding-top: 1rem; /* Add some padding to the top for better spacing */
  padding-bottom: 1rem; /* Add padding to the bottom for better spacing */
}

.social-icons-guidepage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.social-icons-guidepage a {
  color: #fff;
  font-size: 1.5rem;
  margin: 0 0.5rem;
  text-decoration: none;
}

.social-icons-guidepage a:hover {
  color: #7f84b5;
}

.legal-text-guidepage {
  color: #fff;
  font-size: 0.8rem;
  text-align: center;
}

.guide-line {
  width: 100%;
  height: 1px;
  background-color: #777777;
  margin-top: 10px;
  margin-bottom: 20px;
}

/* Add media queries for smaller screens */
@media screen and (max-width: 900px) {
  .guide-container {
    max-width: 85%;
  }

  /* Center the guide-header and guide-body text on smaller screens */
  .guide-header {
    text-align: left;
  }

  .guide-body {
    text-align: left;
  }
}

/* Add media queries for smaller screens */
@media screen and (max-width: 600px) {
  .guide-container {
    max-width: 85%;
  }

  /* Center the guide-header and guide-body text on smaller screens */
  .guide-header {
    text-align: left;
  }

  .guide-body {
    text-align: left;
  }
}

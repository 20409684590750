/* BottomBar.css */
.bottom-bar {
  bottom: 0;
  width: 100%;
  background-color: #000;
  transition: bottom 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem; /* Add spacing between the legal text and icons */
}

.social-icons a {
  color: #fff;
  font-size: 1.5rem;
  margin: 0 0.5rem; /* Add spacing between the icons */
  text-decoration: none;
}

.social-icons a:hover {
  color: #7f84b5; /* Change the color to blue on hover */
}

.legal-text {
  color: #fff;
  font-size: 0.8rem;
  text-align: center;
}
/* PuzzlePage.css */
body {
  margin: 0;
  background-color: #1d1d1d;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.puzzle-page {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Use auto-fill and fractional units */
  gap: 1rem;
  padding: 1rem;
  padding-top: 140px; /* Add padding to push the content below the menu bar */
}

.puzzle-tile {
  width: 400px; /* Fixed width for the puzzle tile */
  position: relative;
}

.new-puzzle {
  /* Styles for the "NEW" text */
  color: black; /* Initial color for "NEW" */
  font-weight: bold;
  font-size: 1rem;
  position: absolute;
  top: 0.5rem; /* Adjust the vertical position as needed */
  left: 0.5rem; /* Adjust the horizontal position as needed */
  animation: flashAnimation 0.6s infinite alternate; /* Apply the flashing animation */
}

@keyframes flashAnimation {
  from {
    color: black; /* Start with black color */
  }
  to {
    color: rgb(0, 255, 0); /* End with green color */
  }
}

.puzzle-title {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 1rem); /* Subtract the space taken by the "NEW" text */
  padding: 0.5rem;
  z-index: 2; /* Ensure the title is on top of the image */
  color: #fff; /* Set the title color to white */
  text-align: center; /* Center the title */
  font-size: 1rem; /* Adjust the font size as needed */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Truncate with ellipsis if text overflows */
  text-overflow: ellipsis;
}

.puzzle-box {
  width: 100%;
  aspect-ratio: 4/3; /* Maintain 4:3 aspect ratio */
  border: 1px solid #ccc;
  overflow: hidden; /* Ensure images and descriptions stay within the box */
  position: relative; /* To position the puzzle details within the puzzle box */
}

.puzzle-box img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio while covering the entire box */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image within the box */
  z-index: 1; /* Ensure the image is behind the puzzle details */
}

.puzzle-details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff; /* Text color for the puzzle details */
  z-index: 2; /* Ensure the details are on top of the image */
  display: flex;
  justify-content:space-around;
  padding: 0.5rem; /* Add some padding to the puzzle details */
}

.puzzle-date,
.puzzle-difficulty {
  font-size: 0.8rem;
}

.puzzle-difficulty {
  white-space: nowrap; /* Prevent text wrapping */
}

.puzzle-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%; /* Increased the modal width */
  max-height: 80%; /* Adjusted the max height */
  background-color: #222;
  border: 2px solid #fff;
  padding: 1rem;
  color: #fff;
  overflow-y: auto;
}

.puzzle-modal img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.puzzle-modal-title {
  text-align: center;
  font-size: 1.5rem; /* Increase the title font size */
  margin-bottom: 1rem;
}

.puzzle-modal-details {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.puzzle-modal-description {
  font-size: 0.9rem;
}

/* Add this CSS for the flex container */
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.puzzle-flex-container {
  flex: 1;
  display: flex;
  flex-direction: column; /* Stack the content vertically */
}

.sort-dropdown {
  position: absolute;
  top: 80px; /* Shift the SortDropdown downwards by 80px */
  right: 27px;
  z-index: 999; /* Ensure the dropdown appears above other content */
}

.sort-dropdown label {
  margin-right: 5px;
  color: #fff;
  font-size: 14px;
}

.sort-dropdown select {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #ccc;
}

.blurred-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  filter: blur(0.5px); /* Apply the blur effect with a radius of 5px */
}

@media screen and (max-width: 800px) {
  .puzzle-page {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Adjusted to display 1 tile per row for smaller screens */
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 600px) {
  .puzzle-page {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjusted to display 1 tile per row for even smaller screens */
  }

  .puzzle-flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Adjust the puzzle-tile and puzzle-box styles */
  .puzzle-tile {
    width: 100%;
  }

  .puzzle-box {
    width: 100%;
  }

  /* Remove the height from the image, and let it adjust according to the aspect ratio */
  .puzzle-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the image within the box */
    z-index: 1; /* Ensure the image is behind the puzzle details */
  }
}

.menu-bar {
  background-color: #000;
  display: flex;
  align-items: center;
  height: 40px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 0 20px; /* Add some horizontal padding to create space around the links */
}

.menu-link {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin: 0;
  transition: color 0.3s ease;
}

/* Add the hover/focus styles for the links */
.menu-link:hover,
.menu-link:focus {
  color: #7f84b5; /* Change the color to blue on hover/focus */
}

/* Add a media query for screens with max-width 400px */
@media screen and (max-width: 800px) {
  .menu-bar {
    padding: 0;
    justify-content: center; /* Center items horizontally */
  }

  .menu-link {
    padding: 0.2rem 0.5rem; /* Reduce the padding even more for very small screens */
  }
}

@media screen and (max-width: 600px) {
  .menu-bar {
    padding: 0;
    justify-content: center; /* Center items horizontally */
  }

  .menu-link {
    padding: 0.2rem 0.5rem; /* Reduce the padding even more for very small screens */
  }
}


/* LandingPage.css */
body {
  margin: 0;
  overscroll-behavior: none;
  overflow-x: hidden;
  background-color: #000000;
}

.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative; /* Add this line */
}

.landing-page video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Montserrat', sans-serif;
  font-size: 3rem;
  color: #fff;
  transition: color 0.3s ease; /* Add transition effect for color change */
  z-index: 1;
}

.title:hover,
.title:focus {
  color: #7f84b5; /* Change the color to purple on hover/focus */
}

.links {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: #000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
}

/* Separate class for the default color of the links */
.menu-link {
  color: #fff;
  text-decoration: none;
  font-size: 1rem; /* Updated font size */
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  /* Remove the border */
  border: none;
  /* Add transition for fading effect */
  transition: color 0.3s ease;
}

.puzzles-link {
  cursor: pointer;
  position: relative;
}

.dropdown {
  position: absolute;
  top: calc(100%); /* Shift the dropdown down */
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  padding: 10px; /* Add padding around the links inside the dropdown */
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
}

.puzzles-link:hover .dropdown {
  visibility: visible;
  opacity: 1;
}

/* Reset the text decoration and keep the original font size */
.dropdown-link {
  text-decoration: none;
  font-size: 1rem;
  color: #fff; /* Set default text color to white */
  /* Add margin between the links */
  margin: 5px 0;
}

/* Add the hover/focus styles for the links inside the dropdown */
.dropdown-link:hover,
.dropdown-link:focus {
  color: #7f84b5; /* Change the color to blue on hover/focus */
}

/* Glitch effect for WWK title on landing page */
.stack {
  display: grid;
  grid-template-columns: 1fr;
}

.stack span {
  font-weight: bold;
  grid-row-start: 1;
  grid-column-start: 1;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 1000ms cubic-bezier(.46,.29,0,1.24) 1 backwards calc(var(--index) * 120ms), glitch 2s ease infinite 2s alternate-reverse;
}

.stack span:nth-child(odd) { --glitch-translate: 8px; }
.stack span:nth-child(even) { --glitch-translate: -8px; }

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  };
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  4%, 100% {  text-shadow: none; transform: none; }
}

/* Add a media query to adjust the layout on smaller screens */
@media (max-width: 600px) {
  .links {
    flex-wrap: wrap;
    height: auto;
  }

  .links a {
    margin: 0.5rem;
  }
}

/* BottomBar that is specifically just in the LandingPage */
.bottom-bar-landing {
    position: absolute;
    bottom: -120px;
    width: 100%;
    background-color: #000;
    transition: bottom 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .social-icons-landing {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem; /* Add spacing between the legal text and icons */
  }
  
  .social-icons-landing a {
    color: #fff;
    font-size: 1.5rem;
    margin: 0 0.5rem; /* Add spacing between the icons */
    text-decoration: none;
  }
  
  .social-icons-landing a:hover {
    color: #7f84b5; /* Change the color to blue on hover */
  }
  
  .legal-text-landing {
    color: #fff;
    font-size: 0.8rem;
    text-align: center;
  }

.loading-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
/* PuzzleDetailsPage.css */
body {
  margin: 0;
  background-color: #1d1d1d; /* Set the background color for the whole screen */
}

.puzzle-details-page {
  flex: 1;
  padding: 1rem;
  color: #fff;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 40px;
}

.puzzle-details-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

img {
  max-width: 100%;
  max-height: 90vh; /* Limit the image height to 90% of the viewport height */
  object-fit: cover; /* Adjust image size while maintaining aspect ratio */
  margin-bottom: 1rem;
  display: block; /* Added to center the image */
  margin-left: auto; /* Added to center the image */
  margin-right: auto; /* Added to center the image */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.puzzle-details-page p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-align: left; /* Align the puzzle difficulty and description to the left */
  margin-left: 1rem; /* Adjust the margin-left value to move the content closer to the center */
}

.solution-link-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.solution-link {
  font-size: 1rem;
  color: #ff0000;
  text-decoration: none;
}

/* Wrapper to ensure BottomBar is pushed to the bottom of the screen */
.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative; /* Add position relative */
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px; /* Add padding to accommodate BottomBar height */
  border: 2px solid rgb(85, 61, 125); /* Set the border properties */
  padding: 15px;
  background-color: #181818;
  animation: glow 10s infinite;
}

@keyframes glow {
  0%, 100% {
    box-shadow: 0px 0px 100px rgba(167, 0, 167, 0.4);
  }
  25% {
    box-shadow: 0px 0px 100px rgba(102, 0, 255, 0.4);
  }
  50% {
    box-shadow: 0px 0px 100px rgba(0, 0, 255, 0.4);
  }
  75% {
    box-shadow: 0px 0px 100px rgba(61, 0, 174, 0.4);
  }
}

/* Add the show and hide classes -- specifically for showing the entire container only once image is rendered */
.show {
  display: block; /* Show the content */
}

.hide {
  display: none; /* Hide the content */
}

/* For loading indicator */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust the height as needed */
  font-size: 1.5rem;
  color: #a80202;
  white-space: pre; /* Preserve white spaces in the text */
}

.loading-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.spinner-container-puzzlepage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .puzzle-details-page {
    padding: 1rem;
    max-width: 100%; /* Adjust the max-width for smaller screens */
  }

  img {
    max-height: 50vh; /* Adjust the max-height for smaller screens */
  }

  .puzzle-details-page p {
    margin-left: 0; /* Reset the margin-left for smaller screens */
  }
}
